<template>
<v-app>
    <div>
        <v-alert dark dismissible v-model="alert" border="left" class="alert" :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
            {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="mb-8 mt-8 text-center">
                <h3>Edit Personnel Deduction</h3>
                <hr/>
            </div>
            <v-form ref="salarycomponent" v-model="valid">
                <v-row no-gutters>
                    <v-col>
                      <TextField :disable="'true'" v-model="employeeName" :label="'Personnel Name'" />
                    </v-col>
                    <v-col>
                      <TextField type="date" placeholder="Effective Date" v-model="formData.effectiveDate" />
                    </v-col>
                    <v-col>
                      <SelectField @onChange="onTypeChange" :items="salaryComponents" v-model="formData.salaryComponent" :label="'Deduction Type'" :itemText="'description'" :itemValue="'id'" :returnObject="true" />
                    </v-col>
                    <v-col style="text-align: left">
                      <span class="alert-error" v-if="showError">Maximum deduction rate is 33%</span>
                    </v-col>
                    <v-col>
                      <v-text-field @blur="formatAmount" v-model="formData.dedecutionAmount" :label="typeLabel" outlined dense />
                      <!-- <TextField @blur="formatAmount" v-model="formData.dedecutionAmount" :label="typeLabel" /> -->
                    </v-col>
                    <v-col v-if="disableYear == false">
                      <SelectField :items="years" v-model="formData.deductionYear" :label="'Year'" :itemText="'name'" :itemValue="'id'" :returnObject="false" />
                    </v-col>
                    <v-col v-if="showUpload === true">
                      <v-file-input
                        dense
                        outlined
                        label="File Upload"
                        v-model="files"
                      />
                    </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <Button :label="'Submit'" :btnType="'Submit'" @onClick="onSave" :disabled="!valid" :isLoading="saving" class="mr-4" />

                    <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
                  </v-col>
                </v-row>
            </v-form>
            <!-- <p>{{personnel}}</p> -->
        </div>
    </div>
</v-app>
</template>

<script>
import Button from '@/components/ui/form/Button.vue'
import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import { employeeDeductionService, salaryComponentService } from '@/services'
import moment from 'moment'

export default {
  components: {
    TextField,
    Button,
    SelectField
  },
  props: ['value'],
  data () {
    return {
      perSearch: false,
      selectedPersonnel: [],
      salaryComponents: [],
      years: [],
      files: null,
      showUpload: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      saving: false,
      employeeName: '',
      disableYear: false,
      maximumAmount: 33,
      showError: false,
      typeLabel: 'Deduction Amount',
      formData: {
        employeeId: '',
        salaryComponent: '',
        dedecutionAmount: 0,
        deductionYear: 0,
        effectiveDate: ''
      },
      search: '',
      categories: [],
      personnelSearch: [],
      personnel: [],
      selected: [],
      headers: [],
      medicalRecords: [],
      searching: false,
      creating: false,
      searchHeaders: [
        {
          text: 'name',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'department',
          value: 'departmentName'
        },
        {
          text: 'rotation',
          value: 'rotationName'
        }
      ],
      personnelHeaders: [
        {
          glutenfree: true,
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onTypeChange (data) {
      this.formData.dedecutionAmount = 0
      if (data.description.trim().includes('Voluntary Contribution')) {
        this.disableYear = true
        this.typeLabel = 'Deduction Rate (%)'
        this.showUpload = false
      } else {
        this.disableYear = false
        this.showError = false
        this.typeLabel = 'Deduction Amount'
        this.showUpload = true
      }
    },
    onSave () {
      this.saving = true
      const payload = {
        dedecutionAmount: this.formData.dedecutionAmount,
        deductionYear: this.formData.deductionYear,
        employeeId: this.formData.employeeId,
        salaryComponentId: this.formData.salaryComponent.id,
        effectiveDate: this.formData.effectiveDate
      }

      employeeDeductionService.saveEmployeeDeduction(payload).then(result => {
        this.saving = false
        this.showAlertMessage('Personnel deduction successfully saved', 'success')
        // this.$refs.medicalRecord.reset()
        this.formData.dedecutionAmount = 0
        this.formData.deductionYear = 0
        this.formData.employeeId = 0
        this.formData.salaryComponent = null
        this.employeeName = ''
        this.selectedPersonnel = []
        this.search = ''
        this.formData.effectiveDate = ''
        this.getRequiredData()
      }).catch(() => {
        this.showAlertMessage('Unable to save personnel deduction please try again later', 'error')
        this.saving = false
      })
    },
    getRequiredData () {
      this.salaryComponents = []
      salaryComponentService.getDeductions().then(result => {
        this.salaryComponents = result.data
      })

      this.years = []
      const year = moment().year()
      this.years.push({ name: year - 1, id: year - 1 })
      this.years.push({ name: year, id: year })
    },
    formatMoney (amount, decimalCount = 2, decimal = '.', thousands = ',') {
      try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? '-' : ''

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
        let j = (i.length > 3) ? i.length % 3 : 0

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
      } catch (e) {
        // console.log(e)
      }
    },
    formatAmount () {
      const deductonType = this.formData.salaryComponent.description.trim()
      if (!deductonType.includes('Voluntary Contribution')) {
        const amount = this.formData.dedecutionAmount
        if (isNaN(amount)) {
          this.valid = false
        } else {
          this.formData.dedecutionAmount = this.formatMoney(amount)
          this.valid = true
        }
      }
    },
    formatToNumber () {
      if (!isNaN(this.formData.dedecutionAmount)) {
        this.formData.dedecutionAmount = Number(this.formData.dedecutionAmount)
      }
    }
  },
  watch: {
    'formData.dedecutionAmount': function (val) {
      const deductonType = this.formData.salaryComponent.description.trim()
      if (val > 33 && deductonType.includes('Voluntary Contribution')) {
        this.valid = false
        this.showError = true
      } else {
        this.valid = true
        this.showError = false
      }
    }
  },
  mounted () {
    this.getRequiredData()
  }
}
</script>

<style scoped>
  .radiogroup {
      width: 50%;
      align-items: flex-start !important;
  }

  .row .col {
      box-shadow: none !important;
      padding: 0 !important;
  }

  .alert {
      position: fixed;
      z-index: 9999;
      top: 5px;
      min-width: 50%;
  }

  .alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
  }

  .alert-error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
  }

  .btn-style {
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
  }

  .text-style {
      text-transform: capitalize;
  }

  .actionBtn {
      display: flex;
      justify-content: flex-end;
  }

  .v-data-table__wrapper {
      overflow-y: auto !important;
      max-height: 400px !important;
  }
</style>
